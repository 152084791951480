import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Header from "../Components/Header";
import Button from "../Components/Button";
import { Grid } from "@mui/material";
import Rodape from '../Components/Rodape';

const Categoria = () => {

    const [subgrupos, setSubGrupos] = useState([]);
    const navigate = useNavigate();
    // fetch('http://186.218.207.6:3000/subgrupos', {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     }
    // })
    // .then((response) => {
    //     setSubGrupos(response.data);
    //     console.log(response)
    // })
    // .catch((error) => {
    //     console.error('Erro na requisição:', error);
    // },[]);
    useEffect(() => {   
        axios.get('http://186.218.207.6:3000/subgrupos')
        .then((response) => {
            setSubGrupos(response.data);
        },)
        .catch((error) => {
            console.error('Erro na requisição:', error);
        });
    },[]);

    const handleNavigate = (subgrupo) => {
        const formattedSubgrupo = encodeURIComponent(subgrupo.replace(/ /g, '-'));
        navigate(`/CATEGORIAS/${formattedSubgrupo}`);
    };

    return(
        <div>
            <Header/>
            <div className="bodyContainer">
                <Grid columns={{ xs: 4, sm: 8, md: 12 }} container>
                    <Grid item>
                        <Grid container justifyContent="center" spacing={3} width={'100%'} marginLeft={0}>
                            {subgrupos.map((subgrupo, index) => (
                                <Button key={index} name={subgrupo.subgrupo} onClick={() => handleNavigate(subgrupo.subgrupo)}/>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Rodape/>
        </div>
    )
}
export default Categoria;