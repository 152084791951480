import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function Login() {
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const primary = '#9E1518';
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://186.218.207.6:3000/login', { user, pwd });
      localStorage.setItem('token', response.data.token);
      if (response.status = 200){
        navigate('/dashboard');
      }
    } catch (error) {
      setMessage(error.response.data.message);
      alert('Usuário ou Senha inválido!');
    }
  };

  return (
    <div className='bg-login'>
      <div className="loginContainer">
        <div className="loginContent">
          <img className='logoLogin' src='/images/toplogo.png'></img>
          <div className='formLogin'>
            <TextField 
                id="outlined-basic"
                label="Usuário" 
                variant="outlined"
                sx={{width:300, backgroundColor:'#fff',borderRadius:1}}
                color='error'
                value={user}
                onChange={e => setUser(e.target.value)}
            />
            <FormControl sx={{width:300, marginTop:1, backgroundColor:'#fff',borderRadius:1}}
                color="error" variant="outlined" value={pwd} onChange={e => setPwd(e.target.value)}>
                <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Password"
                />
            </FormControl>
            <Button onClick={handleLogin} variant="contained" sx={{backgroundColor:primary, width:300, height:56, fontFamily:'Montserrat', fontSize:18, '&:hover': {backgroundColor:primary}, marginTop:2}}>entrar</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
